<template>
	<div class="main-content">
		<div>
			<div class="row">
				<div class="col-12">
					<section class="card p-4">
						<header class="flex justify-between w-full">
							<h4 class="card-header-title">Payment Options</h4>
							<button type="button" class="btn btn-primary btn-sm" @click="isEditingPaymentOptions ? savePaymentOptions() : isEditingPaymentOptions = true" v-if='!isEditingPaymentOptionsLoading'>
								{{ isEditingPaymentOptions ? 'Save' : 'Edit' }}
							</button>
							<div v-else class="spinner-border mr-[20px] !w-3 !h-3"></div>
						</header>
						<div class="flex flex-col gap-4" v-if='!loading'>
							<span class='text-xs'>Select the payment option you want to be visible to all Shuttler users (Optional)</span>
							<div class="flex mt-3 gap-5 flex-wrap">
								<label :for="option.id" class='flex items-center' v-for='option in paymentOptions' :key='option.id'>
								<b-form-checkbox  class="v-checkbox" :disabled='!isEditingPaymentOptions || isEditingPaymentOptionsLoading' :value="option.id" v-model='selectedPaymentOptions' /> {{
									option.title }}</label>
							</div>
						</div>

						<div v-else class="spinner-border justify-center flex mx-auto"></div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import Swal from 'sweetalert2'

const loading = ref(false)
const isEditingPaymentOptionsLoading = ref(false)
const isEditingPaymentOptions = ref(false)
const paymentOptions = ref([])
const selectedPaymentOptions = ref([])

const savePaymentOptions = () =>
{

	isEditingPaymentOptionsLoading.value = true
	axiosInstance.patch('/v1/bulk/general-payment-options', { payment_options_ids: selectedPaymentOptions.value })
		.then(({ data }) =>
		{
			isEditingPaymentOptionsLoading.value = false
			isEditingPaymentOptions.value = false
			Swal.fire({
				icon: 'success',
				title: 'Payment options updated successfully',
				showConfirmButton: false,
				timer: 1500
			})
		})
}
const fetchPaymentOptions = async () =>
{
	loading.value = true
	const res = await axiosInstance.get('/v1/payment-options')
	const selectedRes = await axiosInstance.get('/v1/general-payment-options')
	selectedPaymentOptions.value = selectedRes.data.data.map((option) => option.payment_options_id)
	paymentOptions.value = res.data.data
	loading.value = false

}
fetchPaymentOptions()
</script>
